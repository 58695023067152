import React, { useState, useEffect, useRef, useContext } from 'react'
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card'
import { RemindersList } from 'components/reminders-component/reminders-list';
import { RemindersContext } from "contexts/RemindersContext";
import { DataContext } from "contexts/DataContext";
import { Text } from 'components/catalyst/text';
import { PlusIcon } from 'lucide-react';
import ResponsiveModal from 'components/reminders-component/components/responsive-modal';
import { ReminderCreate } from '../../components/reminders-component/reminder-create';
import { Button } from 'components/ui/button';

export function Reminders() {
  const { reminders } = useContext(RemindersContext);
  const { customerReportPriorityData } = useContext(DataContext);

  const safeReminders = Array.isArray(reminders) ? reminders : [];
  const filteredReminders = safeReminders.filter(reminder => 
    reminder.system_generated != true && reminder.status == "active"
  );

  return (
    <Card className="h-full">
      <CardHeader>
        <h2 className="text-lg font-semibold">Important Reminders</h2>
        <div className="text-sm mt-2">
          <strong>{filteredReminders.length}</strong> priority reminders
        </div>
      </CardHeader>
      <CardContent className="space-y-4">
        {filteredReminders.length === 0 ? (
          <Text>There are no reminders.</Text>
        ) : (
          <RemindersList items={filteredReminders} scrollHeight={400} className="overflow-y-auto" />
        )}
      </CardContent>
      <CardFooter>
        <ResponsiveModal
          trigger={
            <Button variant="outline" className="w-full">
              <PlusIcon className="h-4 w-4 mr-2" />
              Create New Reminder
            </Button>
          }
          title="Create a New Reminder"
          description={<ReminderCreate handleClose={() => setIsOpen(false)} />}
        />
      </CardFooter>
    </Card>
  );
}
