import React, { useState } from 'react'
import { Calendar, Mail, Phone, X } from 'lucide-react'

import { Badge } from "components/catalyst/badge"
import { Button } from "components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "components/ui/card"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/ui/carousel"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog"
import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import { ScrollArea } from "components/ui/scroll-area"
import { Textarea } from "components/ui/textarea"
import CalendarEvent from '../customer-template/CalendarEvent';

// Mock data for action cards
const initialActionCards = [
  {
    id: 1,
    customerName: 'XYZ Inc.',
    type: 'Churn Risk',
    description: 'Customer hasn\'t engaged with our platform in 30 days.',
    urgency: 'high',
    recommendedAction: 'call',
    preGeneratedMessage: 'Hi [Contact Name], I noticed you haven\'t used our platform recently. I\'d love to schedule a quick call to discuss any challenges you might be facing and how we can help.',
  },
  {
    id: 2,
    customerName: 'ABC Ltd.',
    type: 'Upsell Opportunity',
    description: 'Customer has been using premium features in trial mode frequently.',
    urgency: 'medium',
    recommendedAction: 'email',
    preGeneratedMessage: 'Dear [Contact Name], I hope this email finds you well. I noticed you\'ve been making great use of our premium features. I\'d love to discuss how a full upgrade could benefit your workflow.',
  },
  {
    id: 3,
    customerName: 'LMN Corp.',
    type: 'Feedback Required',
    description: 'Customer recently upgraded their subscription.',
    urgency: 'low',
    recommendedAction: 'email',
    preGeneratedMessage: 'Hello [Contact Name], Thank you for upgrading your subscription with us. We\'d love to hear about your experience so far. Could you spare a few minutes for a quick survey?',
  },
  {
    id: 4,
    customerName: 'PQR Solutions',
    type: 'Renewal Coming Up',
    description: 'Customer\'s subscription is due for renewal in 2 weeks.',
    urgency: 'medium',
    recommendedAction: 'call',
    preGeneratedMessage: 'Hello [Contact Name], I hope you\'re doing well. I wanted to touch base regarding your upcoming subscription renewal. Do you have a few minutes to discuss your experience with our platform and any new features you might be interested in?',
  },
  {
    id: 5,
    customerName: 'EFG Enterprises',
    type: 'New Feature Adoption',
    description: 'Customer hasn\'t tried our new analytics dashboard yet.',
    urgency: 'low',
    recommendedAction: 'email',
    preGeneratedMessage: 'Hi [Contact Name], We recently launched a new analytics dashboard that I think could be really valuable for your team. Would you like me to set up a quick demo to show you how it can help streamline your reporting process?',
  },
  {
    id: 6,
    customerName: 'HIJ Corporation',
    type: 'High Usage',
    description: 'Customer is approaching their usage limit for the month.',
    urgency: 'high',
    recommendedAction: 'call',
    preGeneratedMessage: 'Hello [Contact Name], I noticed that you\'re close to reaching your monthly usage limit. I\'d love to hop on a quick call to discuss your current needs and see if we should adjust your plan to better accommodate your growth.',
  },
]

const colorMapBackground = {
  'Delayed Delivery': 'orange',
  'Churn Risk': 'red',
  'Purchase Potential': 'green',
  'Order Inactivity': 'blue',
  'Upsell Opportunity': 'purple',
  'Purchase Frequency Drop': 'amber',
  'Low Digital Revenue Pct': 'teal',
  'Late Payments': 'pink',
  'Net New Customer': 'yellow',
  'Customer Journey': 'indigo',
  'New Customer Promo': 'cyan',
};

const getBadgeColor = (label) => {
  return colorMapBackground[label] || 'gray'; // Fallback to gray if not found
};

export default function ActionCards() {
  const [actionCards, setActionCards] = useState(initialActionCards)

  const ActionCard = ({ card }) => (
    <Card className="flex flex-col h-full w-[350px]">
      <CardHeader>
        <div className="flex justify-between items-start">
          <div>
            <CardTitle className="text-lg">{card.customerName}</CardTitle>
            <div className="flex items-center gap-2 text-sm text-muted-foreground mt-1">
              <Badge color={getBadgeColor(card.type)}>
                {card.type}
              </Badge>
            </div>
          </div>
          <Badge variant={card.urgency === 'high' ? 'destructive' : card.urgency === 'medium' ? 'default' : 'secondary'}>
            {card.urgency}
          </Badge>
        </div>
      </CardHeader>
      <CardContent className="flex-grow">
        <p className="text-base mb-4">{card.description}</p>
        <ScrollArea className="h-[200px] w-full rounded-md border">
          <div className="p-4">
            <h4 className="text-sm font-semibold mb-2">Recommended Action: {card.recommendedAction === 'call' ? 'Call' : 'Email'}</h4>
            <p className="text-sm leading-relaxed">{card.preGeneratedMessage}</p>
          </div>
        </ScrollArea>
      </CardContent>
      <CardFooter className="flex flex-col ">
        <Dialog>
          <DialogTrigger asChild>
            <Button size="sm" className="bg-blue-600 text-white hover:bg-blue-700 w-full">
              {card.recommendedAction === 'call' ? <Phone className="mr-2 h-4 w-4" /> : <Mail className="mr-2 h-4 w-4" />}
              {card.recommendedAction === 'call' ? 'Call' : 'Email'}
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>{card.recommendedAction === 'call' ? 'Call Notes' : 'Send Email'}</DialogTitle>
              <DialogDescription>
                {card.recommendedAction === 'call' 
                  ? 'Record the details of your call here.' 
                  : 'Review and send the pre-generated email.'}
              </DialogDescription>
            </DialogHeader>
            {card.recommendedAction === 'call' ? (
              <div className="grid gap-4 py-4">
                <Textarea placeholder="Enter your call notes here..." />
              </div>
            ) : (
              <div className="grid gap-4 py-4">
                <Textarea defaultValue={card.preGeneratedMessage} />
              </div>
            )}
            <DialogFooter>
              <Button type="submit">{card.recommendedAction === 'call' ? 'Save Notes' : 'Send Email'}</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <div className="flex space-x-2 w-full mt-2">
          <Dialog>
            <DialogTrigger asChild>
              <Button size="sm" variant="outline" className="flex-1">
                <Calendar className="mr-2 h-4 w-4" />
                Add Event
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
              <CalendarEvent 
                customerName={card.customerName}
                customerId={card.id}
              />
            </DialogContent>
          </Dialog>
          <Button size="sm" variant="outline" className="flex-1 text-rose-500 hover:bg-rose-50">
            <X className="mr-2 h-4 w-4" />
            Ignore
          </Button>
        </div>
      </CardFooter>
    </Card>
  )

  return (
    <div className="w-full max-w-[1140px] mx-auto px-4 relative overflow-visible">
      <Carousel
        opts={{
          align: "start",
          loop: true,
          containScroll: "trimSnaps"
        }}
        className="w-full relative"
      >
        <CarouselContent className="px-4">
          {actionCards.map((card, index) => (
            <CarouselItem key={card.id} className="basis-auto">
              <div className="p-1">
                <ActionCard card={card} />
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="absolute -left-4 top-1/2 -translate-y-1/2 h-8 w-8 bg-white shadow-md hover:bg-gray-50" />
        <CarouselNext className="absolute -right-4 top-1/2 -translate-y-1/2 h-8 w-8 bg-white shadow-md hover:bg-gray-50" />
      </Carousel>
    </div>
  )
}