import React from 'react';
import { CustomerTable } from './CustomerTable';
import { Reminders } from './Reminders';
import { NotifyCards } from './NotifyCards';
import { CustomerInsights } from './CustomerInsights';
import { Breakdown } from './Breakdown';
import ActionCards from './ActionCards';
import { Card, CardContent, CardHeader } from 'components/ui/card';

export default function Index() {
  return (
    <div className="w-full border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="flex flex-wrap w-full pt-4">
        <div className="w-full">
          <NotifyCards />
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 mt-4">
            <div className="col-span-1 lg:col-span-5 h-full">
              <Reminders />
            </div>
            <div className="col-span-1 lg:col-span-7 h-full">
              <Card className="h-full">
                <CardHeader>
                  <h2 className="text-lg font-semibold">Action Items</h2>
                  <div className="text-sm mt-2">
                    <strong>##</strong> action items
                  </div>
                </CardHeader>
                <CardContent>
                  <ActionCards />
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mt-4">
            <div className="md:col-span-2 order-1 md:order-2">
              <CustomerInsights />
              <div className="mt-4">
                <Breakdown />
              </div>
            </div>
            <div className="md:col-span-3 order-2 md:order-1">
              <CustomerTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}